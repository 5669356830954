@font-face {
  font-family: 'AreaNormal';
  src: url('/fonts/Area-Regular.woff2');
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'AreaNormal';
  src: url('/fonts/Area-Semibold.woff2');
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: 'AreaNormal';
  src: url('/fonts/Area-Bold.woff2');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'AreaNormal';
  src: url('/fonts/Area-Black.woff2');
  font-weight: 900;
  font-display: fallback;
}

@font-face {
  font-family: 'AreaNormal';
  src: url('/fonts/Area-Extrablack.woff2');
  font-weight: 950;
  font-display: fallback;
}
